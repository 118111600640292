import { LINKS } from '@utils/constants';
import { SVGPicker } from '@utils/helpers';
import { StaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Grid from '@components/Layout/Grid/Grid';
import Card from '@components/Molecule/Card/Card';
import H from '@components/Typography/H';

const PostListing = ({
	theme = 'standard',
	large,
	grow,
	pageContext,
	posts,
}: {
	posts?: [];
	pageContext?: any;
	theme?: 'standard' | 'dark';
	large?: boolean;
	grow?: boolean;
}) => {
	return (
		<Grid gap="sm">
			{posts?.map(({ node }: { node: any }, key: number) => {
				const { uid } = node;
				const { title, primary_image } = node.data;

				return (
					<Card
						id={key}
						to={`${LINKS.MEDIA}/${uid}`}
						title={title?.text}
						large={large}
						thumbnail={getImage(primary_image.gatsbyImageData)}
						grow={grow}
						theme={theme}
						svg={title?.text && SVGPicker(uid)}
						key={key}>
						{title?.text && (
							<H level={5} as="h3">
								{title?.text}
							</H>
						)}
					</Card>
				);
			})}
		</Grid>
	);
};

export default PostListing;
