import { LINKS } from '@utils/constants';
import { SVGPicker } from '@utils/helpers';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Hero from '@components/Hero/Hero';
import Content from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import Lead from '@components/Layout/Lead/Lead';
import PageSection from '@components/Layout/PageSection/PageSection';
import PostListing from '@components/Listing/PostListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Card from '@components/Molecule/Card/Card';
import { MotionCopy } from '@components/Typography/Copy';
import H, { MotionH } from '@components/Typography/H';

const PostListingTemplate = ({
	data,
	pageContext,
	theme = 'standard',
	large,
	grow,
}: {
	data?: any;
	pageContext?: any;
	theme?: 'standard' | 'dark';
	large?: boolean;
	grow?: boolean;
}) => {
	const { posts } = pageContext;

	return (
		<Page>
			<SEO title="Media | UHV Design" />

			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'Shot of UHV device',
				}}
				type="box">
				<Content>
					<MotionH level={2} as="h1">
						Media
					</MotionH>

					<MotionH level={3} as="h2" spacing="xl">
						Our focus
					</MotionH>

					<MotionCopy spacing="sm">
						We specialise in the design, manufacture and supply of
						innovative market-leading HV and UHV motion and heating
						products.
					</MotionCopy>

					<MotionCopy>
						We are proud to lead the market in the design,
						manufacture, and supply of cutting-edge high vacuum (HV)
						and ultra-high vacuum (UHV) motion and heating products.
						Our latest advancements continue to set industry
						standards, offering precise control and efficiency for
						demanding applications. From groundbreaking product
						launches to major project collaborations, we remain at
						the forefront of innovation, helping industries achieve
						peak performance. Stay updated with our news for the
						latest in HV and UHV technology.
					</MotionCopy>
				</Content>
			</Hero>
			<PageSection>
				<Content>
					<Lead>
						<MotionH level={3} as="h2">
							Latest posts
						</MotionH>
					</Lead>
				</Content>
				<PostListing posts={posts} />
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "home/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
				original {
					src
				}
			}
		}
	}
`;

export default PostListingTemplate;
